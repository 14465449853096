<template>
  <!-- eslint-disable -->
  <div class="container">
    <div class="title-block">
      <h1>Please complete your registration</h1>
    </div>
    <div class="name-block">
      <input v-model="firstName" placeholder="First Name" />
      <input v-model="lastName" placeholder="Last Name" />
    </div>
    <div class="email-block">
      <input v-model="email" placeholder="Email" @blur="validateEmail" />
    </div>
    <div class="button-block" @click="onSubmitHandler">
      <button>Submit</button>
    </div>
    <div class="error-block">
      <p>{{ errorMsg }}</p>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import axios from "axios";

export default {
  name: "SectionAsaLoginForm",

  provide: {
    heading: { align: "center" },
  },

  mounted() {
    this.consumerCode = this.$route.query.asaconsumerCode;
  },

  data: () => ({
    expand: false,
    firstName: "",
    lastName: "",
    email: "",
    errorMsg: "",
    consumerCode: "",
  }),

  computed: {
    title() {
      return this.$route.name;
    },
    src() {
      return this.$route.meta.src;
    },
    copy() {
      return this.$route.meta.copy;
    },
  },
  methods: {
    validateEmail() {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) ||
        this.email.length === 0
      ) {
        this.errorMsg = "";
      } else {
        this.errorMsg = "Please enter a valid email address";
      }
    },

    onSubmitHandler() {
      // Check if all required fields present
      if (!this.email || !this.lastName || !this.firstName) {
        this.errorMsg = "Please fill out all inputs";
      } else {
        // Make API call to register new user
        axios
          .post("https://api.honely.com/lookup/user/sso-asa/register", {
            "consumer-code": this.consumerCode,
            "first-name": this.firstName,
            "last-name": this.lastName,
            email: this.email,
          })
          .then((response) => {
            // window.location = response.data.url
            window.location.replace(response.data.url);
            return;
          })
          .catch((error) => {
            if (error.response.status === 404 || error.response.status === 400) {
              this.errorMsg = error.response.data.message;
            }
          });
      }
    },
  },
};
</script>

<style>
input {
  padding: 0.5rem;
  background-color: white !important;
  outline: none;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 93%;
}

.title-block {
  margin-top: 0.5rem;
  padding: 1rem;
  text-align: center;
}

.name-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.name-block input {
  width: 45%;
}

.button-block {
  margin-top: 2rem;
}

.button-block button {
  background-color: #24cb43;
  border: none;
  color: white;
  width: 277px;
  font-size: 1em;
}

.button-block button:hover {
  background-color: #37dc55;
}

.email-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 1rem;
}

.error-block p {
  color: #cb2f24;
  margin-top: 1rem;
}

@media only screen and (max-width: 700px) {
  input {
    margin: 0.5rem 0;
  }

  .name-block {
    flex-direction: column;
    width: calc(80% - 1rem);
  }

  .name-block input {
    width: 100%;
  }

  .email-block {
    width: 80%;
    margin-top: 0;
  }

  .button-block {
    margin-top: 1rem;
  }
}
</style>
